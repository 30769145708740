<template>
  <!-- <div style="background-image: url('/images/bg/top.png');" id="top-title">长江文化平台</div> -->
  <!-- <img class="cloud" src="/images/cloud.png">
  <img class="cloud" src="/images/cloud.png">
  <img class="cloud" src="/images/cloud.png">
  <img class="cloud" src="/images/cloud.png"> -->
  <div id="container" ref="canvasContainer"></div>
  <div id="tooltip" ref="tooltip"></div>
  <DataView ref="dataView" />
</template>

<script setup>
import * as THREE from 'three';
import autofit from 'autofit.js'
import DataView from './DataView.vue'
//OrbitControls 是一个附加组件，必须显式导入
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
// SMAA抗锯齿通道
import { SMAAPass } from 'three/addons/postprocessing/SMAAPass.js';
//墨卡托投影转换可以把我们经纬度坐标转换成我们对应平面的2d坐标,d3里面自带墨卡托投影转换
//该引入方式是查阅官网得到的
import * as d3 from "d3";
import { onMounted, onUnmounted, ref } from 'vue';
import TWEEN from '@tweenjs/tween.js';
// 引入CSS3渲染器CSS3DRenderer
import { initCSS3DRender, create3DTag } from '@/hooks/useCSS3DRender';
import { initCSS2DRender, createImgTag, create2DTag } from '@/hooks/useCSS2DRender';
import { createLightPillar } from '@/hooks/map/useMapMarkedLightPillar'
import { createFlyCurve, timerFlyCurve } from '@/utils/flyCurve.js';
import vertexShader from "@/shader/vt.glsl";
import fragmentShader from "@/shader/gm.glsl";

const dataView = ref();

const tagUrl = {
  connected: '/images/icon/connected.png',
  unconnected: '/images/icon/unconnected.png',
  toBeConnected: '/images/icon/to-be-connected.png',
}
let runoffProvinceJsondata = null;

let textureMap = null;
let texturefxMap = null;
let rotatingApertureTexture = null;
let cloudTexture = null;
let riverNormalMap = null;
let sceneBg = null;
let sceneFxBg = null;


// const outsidePlateColor = '#27619d'// 外板块颜色
// const insidePlateColor = '#2d95ff'// 内板块颜色
const outsidePlateColor = '#8f8f8f'// 外板块颜色
const insidePlateColor = '#e3e3e3'// 内板块颜色

const outsideBorderColor = '#AAB0AC';// 边框颜色
const insideBorderColor = '#EAFFE6'
const outlineColor = '#B3F2F8'
const lightColor = '#ffea74';
const sideColor = '#2eaf6e';// 侧面颜色
const hoverPlateColor = '#ffffff'// 悬浮边框颜色
const selectPlateColor = ''// 选中板块颜色
const selectBorderColor = '#ffca38'// 选中边框颜色
const yangtzeRiverColor = {
  startColor: new THREE.Color('#69AFFF'),
  endColor: new THREE.Color('#65e9e7')
}
let beginAnimated = false;
let beginHighlight = '湖北省';
const centerXY = [104.0, 35.5];
// 使用d3的地图投影
let projection = d3.geoMercator().center(centerXY).translate([0, 0]);
let provinceGroup = null;
let cityGroup = null;
let districtGroup = null;
let canvasContainer = ref(null);
let tooltip = ref(null)
let chinaScene, provinceScene, cityScene, camera, renderer, composer, directionalLight1, directionalLight2, background, CSS2dRender, CSS3DRender, raycaster, mouse;
let intersectObject = null;
let rotatingApertureMesh = null;
let control = null;
let mixers = [];
let chinaYangtzeRiverPoints = [];
let provinceYangtzeRiverPoints = [];
let cityYangtzeRiverPoints = [];
let chinaYangtzeRiverLine, provinceYangtzeRiverLine, cityYangtzeRiverLine = null;
let chinaLine = null;
let provinceLabelList = []
let cityLabelList = [];
let districtLabelList = [];
let state = 'china';
let lastCameraPosition = null;
const clock = new THREE.Clock();
const depth = 5;
const scale = 1.8;
const upZ = 3;
let upTweenAnimations = {}; // 用于存储所有正在执行的上升动画
let downTweenAnimations = {};
const growAnimateList = [
  {
    name: '青海省',
    value: 1
  },
  {
    name: '四川省',
    value: 35
  },
  {
    name: '西藏自治区',
    value: 38
  },
  {
    name: '云南省',
    value: 60
  },
  {
    name: '重庆市',
    value: 110
  },
  {
    name: '湖北省',
    value: 120
  },
  {
    name: '湖南省',
    value: 150
  },
  {
    name: '江西省',
    value: 170
  },
  {
    name: '安徽省',
    value: 180
  },
  {
    name: '江苏省',
    value: 190
  },
  {
    name: '上海市',
    value: 195
  },
]

//初始化摄像机
function initCamera() {
  camera = new THREE.PerspectiveCamera(75, canvasContainer.value.offsetWidth / canvasContainer.value.offsetHeight, 0.1, 1000);
  camera.position.set(0, -30, 400);
  camera.lookAt(chinaScene.position);
}
//初始化renderer
function initRenderer() {
  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setSize(canvasContainer.value.offsetWidth, canvasContainer.value.offsetHeight);
  renderer.physicallyCorrectLights = true;
  renderer.setPixelRatio(window.devicePixelRatio * 2);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.0;
  // 设置渲染器，允许光源阴影渲染
  // 创建后处理对象EffectComposer，WebGL渲染器作为参数
  composer = new EffectComposer(renderer);
  // 创建一个渲染器通道，场景和相机作为参数
  const renderPass = new RenderPass(chinaScene, camera);
  // 设置renderPass通道
  composer.addPass(renderPass);
  // 放在renderPass之后
  const gammaCorrectionShader = new ShaderPass(GammaCorrectionShader);
  composer.addPass(gammaCorrectionShader);
}
//初始化灯光
function initLight() {
  //   平行光1
  directionalLight1 = new THREE.DirectionalLight('#fff', 2);

  directionalLight1.position.set(10, 40, 50);
  //   平行光2
  directionalLight2 = new THREE.DirectionalLight('#fff', 1);
  directionalLight2.position.set(...centerXY, 30);
  directionalLight1.castShadow = true;
  // 可视化平行光阴影对应的正投影相机对象

  // 设置三维场景计算阴影的范围
  directionalLight1.shadow.camera.left = -80;
  directionalLight1.shadow.camera.right = 80;
  directionalLight1.shadow.camera.top = 110;
  directionalLight1.shadow.camera.bottom = -70;
  directionalLight1.shadow.camera.near = 0.9;
  directionalLight1.shadow.camera.far = 110;
  // const cameraHelper = new THREE.CameraHelper(directionalLight1.shadow.camera);
  // chinaScene.add(cameraHelper);
  directionalLight1.name = 'light';
  directionalLight2.name = 'light';
  // 将光源添加到场景中
  chinaScene.add(directionalLight1);
  chinaScene.add(directionalLight2);
}

function loadTexture() {
  return new Promise((resolve, reject) => {
    const textureLoad = new THREE.TextureLoader();
    textureMap = textureLoad.load('/images/texture/china.png');
    texturefxMap = textureLoad.load('/images/texture/chinaFx.png');
    rotatingApertureTexture = textureLoad.load(
      '/images/texture/lightRound.png'
    );
    cloudTexture = textureLoad.load('/images/cloud.png');
    riverNormalMap = textureLoad.load('/images/waternormals.jpg');
    sceneBg = textureLoad.load('/images/bg/全球背景.png');
    sceneFxBg = textureLoad.load('/images/bg/全球背景Fx.png');
    textureMap.center.set(0.46, 0.43);
    texturefxMap.center.set(0.46, 0.43);
    sceneBg.colorSpace = THREE.SRGBColorSpace;//设置为SRGB颜色空间

    const scaleX = 0.00545;
    const scaleY = 0.00725;
    textureMap.repeat.set(scaleX, scaleY);
    texturefxMap.repeat.set(scaleX, scaleY);
    resolve();
  })

}

//加载json数据
function loadJson() {
  return new Promise((resolve, reject) => {
    const fileLoad = new THREE.FileLoader();
    fileLoad.load('/json/中华人民共和国.json', (provinceData) => {
      fileLoad.load('/json/中华人民共和国_bg.json', (chinaData) => {
        fileLoad.load('/json/长江.json', (yangtzeRiverData) => {
          fileLoad.load('/json/长江干流径流省份.json', (runoffProvinceData) => {
            runoffProvinceJsondata = JSON.parse(runoffProvinceData).runoffProvince;
            const yangtzeRiverJsondata = JSON.parse(yangtzeRiverData);
            const chinaJsondata = JSON.parse(chinaData);
            const provinceJsondata = JSON.parse(provinceData);
            generateChina(chinaJsondata);
            generateProvince(provinceJsondata);
            chinaYangtzeRiverLine = generateMainstreamYangtzeRiver(yangtzeRiverJsondata, depth * 2);
            chinaScene.add(chinaYangtzeRiverLine);
            resolve();
          })
        })
      })
    })
  })

}
function initOutlinePass(...meshName) {
  const meshList = chinaScene.children.filter(item => {
    return meshName.includes(item.name);
  });
  // OutlinePass第一个参数v2的尺寸和canvas画布保持一致
  const v2 = new THREE.Vector2(window.innerWidth, window.innerHeight);
  const outlinePass = new OutlinePass(v2, chinaScene, camera);
  outlinePass.selectedObjects = meshList;

  // //加抗锯齿
  // //获取.setPixelRatio()设置的设备像素比
  // const pixelRatio = renderer.getPixelRatio();
  // // width、height是canva画布的宽高度
  // const smaaPass = new SMAAPass(window.innerWidth * pixelRatio, window.innerHeight * pixelRatio);
  // 设置OutlinePass通道
  composer.addPass(outlinePass);
  // composer.addPass(smaaPass);
}

function initBackground() {
  background = new THREE.Mesh(
    new THREE.PlaneGeometry(940, 600),
    new THREE.MeshPhongMaterial({ map: sceneBg, normalMap: sceneFxBg, transparent: true, opacity: 1 })
  );
  background.position.set(-42, 21, -0.5)
  background.receiveShadow = true;
  background.name = 'background';
  chinaScene.add(background);
}

// 初始化旋转光圈
const initRotatingAperture = (chinaScene, width) => {
  let plane = new THREE.PlaneGeometry(width, width);
  let material = new THREE.MeshPhongMaterial({
    map: rotatingApertureTexture,
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
    depthWrite: false
  });
  let mesh = new THREE.Mesh(plane, material);
  mesh.position.set(0, 20, 0);
  mesh.name = 'rotatingAperture';
  chinaScene.add(mesh);
  return mesh;
};
const initCloud = () => {
  const cloudGroup = new THREE.Group();
  const cloudMaterial = new THREE.MeshBasicMaterial({
    map: cloudTexture,
    transparent: true,
    opacity: 1,
    depthTest: false,
    depthWrite: false
  });
  for (let i = 0; i < 12; i++) {
    const cloudGeometry = new THREE.PlaneGeometry(800, 500);
    const cloudMesh = new THREE.Mesh(cloudGeometry, cloudMaterial);
    let x = Math.random() * 200 - 100;
    let y = Math.random() * 200 - 100;
    cloudMesh.position.set(x, y, i * 20 + 100);
    cloudGroup.add(cloudMesh);
  }
  cloudGroup.name = 'cloud';
  chinaScene.add(cloudGroup);
}
function generateChina(chinaJsondata) {
  // outline边框
  const chinaPoints = [];
  chinaJsondata[0].geometry.coordinates[0][0].forEach((coord, i) => {
    const [x, y] = projection(coord);
    chinaPoints.push(x, -y, depth);
  })
  let chinaGeometry = new LineGeometry();
  chinaGeometry.setPositions(chinaPoints);
  chinaGeometry.instanceCount = 0;
  let chinaMaterial = new LineMaterial({
    linewidth: 3,
    color: outlineColor,
    opacity: 1,

  });
  chinaMaterial.resolution.set(window.innerWidth + 100, window.innerHeight + 100);//这句如果不加宽度仍然无效
  chinaLine = new Line2(chinaGeometry, chinaMaterial);
  chinaLine.name = 'chinaLine';
  chinaScene.add(chinaLine);
  // initOutlinePass(chinaLine)
}

function generateMainstreamYangtzeRiver(yangtzeRiverJsondata, z) {
  // 长江
  const yangtzeRiverPoints = [];
  const yangtzeRiverColors = [];
  chinaYangtzeRiverPoints = [];
  provinceYangtzeRiverPoints = [];
  cityYangtzeRiverPoints = [];
  yangtzeRiverJsondata[0].geometry.coordinates.forEach((coord, i) => {
    const percent = i / (yangtzeRiverJsondata[0].geometry.coordinates.length - 1);
    const [x, y] = projection(coord);
    yangtzeRiverPoints.push(x, -y, z + 0.1);
    chinaYangtzeRiverPoints.push(new THREE.Vector3(x, -y, z + 0.35));
    provinceYangtzeRiverPoints.push(new THREE.Vector3(x, -y, z + 0.2));
    cityYangtzeRiverPoints.push(new THREE.Vector3(x, -y, z + 0.11));
    const interpolatedColor = new THREE.Color().lerpColors(yangtzeRiverColor.startColor, yangtzeRiverColor.endColor, percent);
    yangtzeRiverColors.push(interpolatedColor.r, interpolatedColor.g, interpolatedColor.b);
  })
  let yangtzeRiverGeometry = new LineGeometry();
  yangtzeRiverGeometry.setPositions(yangtzeRiverPoints);
  yangtzeRiverGeometry.setColors(yangtzeRiverColors);
  yangtzeRiverGeometry.instanceCount = 0;
  let yangtzeRiverMaterial = new LineMaterial({
    linewidth: 11,
    vertexColors: true,
    opacity: 1,
  });
  yangtzeRiverMaterial.resolution.set(window.innerWidth + 100, window.innerHeight + 100);//这句如果不加宽度仍然无效
  const Line = new Line2(yangtzeRiverGeometry, yangtzeRiverMaterial);
  Line.castShadow = true;
  Line.name = 'chinaYangtzeRiverLine';
  return Line;
}
function generateTributaryYangtzeRiver(yangtzeRiverJsondata, z) {
  const tributaryGroup = new THREE.Group();
  tributaryGroup.name = 'tributaryGroup';
  yangtzeRiverJsondata.forEach((element) => {
    const tributaryPoints = [];
    const tributaryColors = [];
    element.geometry.coordinates.forEach((coord, i) => {
      const percent = i / (element.geometry.coordinates.length - 1);
      const [x, y] = projection(coord);
      tributaryPoints.push(x, -y, z + 0.1);
      const interpolatedColor = new THREE.Color().lerpColors(yangtzeRiverColor.startColor, yangtzeRiverColor.endColor, percent);
      tributaryColors.push(interpolatedColor.r, interpolatedColor.g, interpolatedColor.b);
    })
    let tributaryGeometry = new LineGeometry();
    tributaryGeometry.setPositions(tributaryPoints);
    tributaryGeometry.setColors(tributaryColors);
    // tributaryGeometry.instanceCount = 0;
    let tributaryMaterial = new LineMaterial({
      linewidth: 3,
      vertexColors: true,
      opacity: 1,
    });
    tributaryMaterial.resolution.set(window.innerWidth + 100, window.innerHeight + 100);//这句如果不加宽度仍然无效
    const Line = new Line2(tributaryGeometry, tributaryMaterial);
    Line.castShadow = true;
    Line.name = 'tributaryLine';
    tributaryGroup.add(Line);
  })
  return tributaryGroup;
}
// 根据JSON数据生成省份几何体
function generateProvince(provinceJsondata) {
  provinceGroup = new THREE.Group();
  provinceGroup.name = 'provinceGroup';
  provinceLabelList = [];
  // 遍历每个省份，创建几何体
  provinceJsondata.features.forEach((element) => {
    let province = new THREE.Object3D();
    province['focus'] = false;
    province['up'] = false;
    const coordinates = element.geometry.coordinates;
    province.properties = element.properties;
    let [x, y] = projection(element.properties.center);
    createName([x, -y], element, province, 'province', depth);
    // createTag([x, -y], element, province);
    if (Array.isArray(coordinates[0][0][0])) {
      coordinates.forEach((multiPolygon) => {
        multiPolygon.forEach((polygon) => {
          generatePolygon(polygon, projection, province, element, 'province', depth, 1.5);
        })
      })
    } else if (Array.isArray(coordinates[0][0])) {
      coordinates.forEach((polygon) => {
        generatePolygon(polygon, projection, province, element, 'province', depth, 1.5);
      })
    }
    provinceGroup.add(province);
  })
  chinaScene.add(provinceGroup);
}
function generateCity(cityJsondata) {
  cityGroup = new THREE.Group();
  cityGroup.name = 'provinceGroup';
  cityLabelList = [];
  // 遍历每个省份，创建几何体
  cityJsondata.features.forEach((element) => {
    let city = new THREE.Object3D();
    city['focus'] = false;
    city['up'] = false;
    const coordinates = element.geometry.coordinates;
    city.properties = element.properties;
    let [x, y] = projection(element.properties.center);
    createName([x, -y], element, city, 'city', depth / 4);
    // createTag([x, -y], element, province);
    if (Array.isArray(coordinates[0][0][0])) {
      coordinates.forEach((multiPolygon) => {
        multiPolygon.forEach((polygon) => {
          generatePolygon(polygon, projection, city, element, 'city', depth / 4, 3);
        })
      })
    } else if (Array.isArray(coordinates[0][0])) {
      coordinates.forEach((polygon) => {
        generatePolygon(polygon, projection, city, element, 'city', depth / 4, 3);
      })
    }
    cityGroup.add(city);
  })
  provinceScene.add(cityGroup);
}
function generateDistrict(districtJsondata) {
  districtGroup = new THREE.Group();
  districtGroup.name = 'districtGroup';
  districtLabelList = [];
  // 遍历每个省份，创建几何体
  districtJsondata.features.forEach((element) => {
    let district = new THREE.Object3D();
    district['focus'] = false;
    district['up'] = false;
    const coordinates = element.geometry.coordinates;
    district.properties = element.properties;
    let [x, y] = projection(element.properties.center);
    createName([x, -y], element, district, 'district', depth / 12);
    // createTag([x, -y], district);
    if (Array.isArray(coordinates[0][0][0])) {
      coordinates.forEach((multiPolygon) => {
        multiPolygon.forEach((polygon) => {
          generatePolygon(polygon, projection, district, element, 'district', depth / 12, 3);
        })
      })
    } else if (Array.isArray(coordinates[0][0])) {
      coordinates.forEach((polygon) => {
        generatePolygon(polygon, projection, district, element, 'district', depth / 12, 3);
      })
    }
    districtGroup.add(district);
  })
  cityScene.add(districtGroup);
}
/**
 * 生成板块
 * @param {*} polygon 
 * @param {*} projection 
 * @param {*} plate 
 * @param {*} element 
 */
const generatePolygon = (polygon, projection, plate, element, name, plateDepth, lineWidth) => {
  const flag = runoffProvinceJsondata.includes(element.properties.name);
  const shape = new THREE.Shape();
  const points = [];
  polygon.forEach((coord, i) => {
    const [x, y] = projection(coord);
    if (i === 0) shape.moveTo(x, -y);
    else shape.lineTo(x, -y);
    points.push(x, -y, plateDepth);
  })
  let line = createLine(points, flag ? insideBorderColor : outsideBorderColor, lineWidth);
  let materialColor = outsidePlateColor;

  const extrudeSettings = { depth: plateDepth, bevelEnabled: false };
  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  const material = new THREE.MeshStandardMaterial({ color: materialColor, normalMap: texturefxMap, map: textureMap, transparent: true, opacity: 0.96 });
  material.normalScale.set(3, 3);
  const shader = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    side: THREE.DoubleSide,
    uniforms: {
      upColor: { value: flag ? new THREE.Color("#B6C7B4") : new THREE.Color("#B6C7B4") },
      downColor: { value: flag ? new THREE.Color("#618c2e") : new THREE.Color("#618c2e") },
    },
  });
  const mesh = new THREE.Mesh(geometry, [material, shader]);
  mesh.castShadow = true;
  mesh.receiveShadow = true;
  mesh.name = name;
  line.name = 'border';
  plate.add(mesh);
  plate.add(line);
}
const createLight = ([x, y], province) => {

  let light = createLightPillar(x, y, 8, lightColor);
  light.position.z = depth + 0.1;
  province.add(light);
}
/**
 * 创建名称
 * @param {*} projection 
 * @param {*} element 
 * @param {*} plate 
 */
const createName = ([x, y], element, plate, name, nameZ) => {
  // if (!runoffProvinceJsondata.includes(element.properties.name)) {
  //   return;
  // }
  let label = create3DTag(element.properties.name, name + '-label', new THREE.Vector3(x, y, nameZ));
  if (name === 'province') {
    label.scale.set(0.1, 0.1, 0.1);
    provinceLabelList.push(label);
  } else if (name === 'city') {
    label.scale.set(0.02, 0.02, 0.02);
    cityLabelList.push(label);
  } else if (name === 'district') {
    label.scale.set(0.004, 0.004, 0.004);
    districtLabelList.push(label);
  }


  plate.add(label);

  label.show();
}
/**
 * 创建图片tag
 * @param {*} 
 * @param {*} plate 
 */
const createTag = ([x, y], plate) => {
  plate.parent.children.forEach(item1 => {
    item1.remove(item1.children.find(item2 => item2.name === '卷轴'));
  })
  // if (runoffProvinceJsondata.includes(element.properties.name)) {
  //   imgUrl = tagUrl.unconnected;
  // } else {
  //   return;
  // }
  let tag = createImgTag('/video/juanzhou_1.avi', 'map-tag', new THREE.Vector3(x, y, depth / 12));
  tag.name = '卷轴'
  plate.add(tag);
}
/**
   * 根据点数据创建闭合的线条
   * @param {*} points 点数据
   * @param {*} material 材质
   * @param {*} linewidth 线条宽度
   * @returns
   */
const createLine = (points, borderColor, linewidth) => {
  let line = null;
  let geometry = new LineGeometry();
  geometry.setPositions(points);
  let material = new LineMaterial({
    linewidth: linewidth,
    color: borderColor,
    opacity: 1,
    transparent: true,
  });
  material.resolution.set(window.innerWidth + 100, window.innerHeight + 100);//这句如果不加宽度仍然无效
  line = new Line2(geometry, material);
  return line;
};

const downAnimate = (objects, scene, name) => {
  objects.forEach(item => {
    if (item.focus) {
      return;
    }
    if (beginAnimated) {
      item.children.filter(item => item.name === name).forEach(item => {
        item.material[0].color.set(insidePlateColor);
      })
    }
    if (item.position.z > 0 && upTweenAnimations[item.id] && upTweenAnimations[item.id].isPlaying()) {
      upTweenAnimations[item.id].stop();
      delete upTweenAnimations[item.id];
    }
    if (!downTweenAnimations[item.id] && scene.getObjectById(item.id)) {
      downTweenAnimations[item.id] = new TWEEN.Tween(scene.getObjectById(item.id).position)
        .to({ z: 0 }, 300)
        .easing(TWEEN.Easing.Sinusoidal.Out)
        .onComplete(() => {
          delete downTweenAnimations[item.id];
        })
        .start();
    }
  })
}
const hoverAnimate = (group, scene, filterList, name, z) => {
  if (!group)
    return;
  const intersects = raycaster.intersectObjects(scene.children, true);
  if (beginAnimated) {
    if (filterList.length > 0) {
      intersectObject = intersects.find(
        (item) => item.object.material && item.object.material.length === 2 && filterList.includes(item.object.parent.properties.name)
      );
    } else {
      intersectObject = intersects.find(
        (item) => item.object.material && item.object.material.length === 2
      );
    }

  }
  if (intersectObject) {
    intersectObject.object.material[0].color.set(hoverPlateColor);
    // 如果该省份的动画尚未创建或已经停止,则创建新的上浮动画
    if (!upTweenAnimations[intersectObject.object.parent.id] || !upTweenAnimations[intersectObject.object.parent.id].isPlaying()) {
      upTweenAnimations[intersectObject.object.parent.id] = new TWEEN.Tween(intersectObject.object.parent.position)
        .to({ z: z }, 300)
        .easing(TWEEN.Easing.Sinusoidal.Out)
        .start();
    }
    let noIntersectObjects = null;
    if (filterList.length > 0) {
      noIntersectObjects = group.children.filter(item => item !== intersectObject.object.parent).filter(item => filterList.includes(item.properties.name));
    } else {
      noIntersectObjects = group.children.filter(item => item !== intersectObject.object.parent);
    }
    downAnimate(noIntersectObjects, scene, name);
  } else {
    if (filterList.length > 0) {
      downAnimate(group.children.filter(item => filterList.includes(item.properties.name)), scene, name);
    } else {
      downAnimate(group.children, scene, name);
    }

  }
}
function growAnimate(name) {
  const provinceGroup = chinaScene.children.find(item1 => item1.name === 'provinceGroup');
  provinceGroup.children.forEach(item => {
    if (item.properties.name === name && item.scale.z === 1) {
      if (!item.up) {
        setTimeout(() => {
          item.children.filter(province => province.type === 'Object3D').forEach(tip => {
            tip.element.style.opacity = 1;
            setTimeout(() => {
              tip.element.style.transition = 'none';
            }, 600)
          })

          item.children.filter(province => province.name === 'province').forEach(province => {
            const color1 = new THREE.Color(outsidePlateColor);
            const color2 = new THREE.Color(insidePlateColor);
            const colorKF = new THREE.KeyframeTrack('province.material[0].color', [0, 1], [color1.r, color1.g, color1.b, color2.r, color2.g, color2.b]);
            const clip = new THREE.AnimationClip('province.material[0].color', 1, [colorKF]);
            mixers.push(new THREE.AnimationMixer(province));
            const animationAction = mixers[mixers.length - 1].clipAction(clip);
            animationAction.play();
            //不循环播放
            animationAction.loop = THREE.LoopOnce;
            animationAction.clampWhenFinished = true;
          })
        }, 650)
        new TWEEN.Tween(item.children.find(children => children.name === 'border').material.uniforms.linewidth)
          .to({ value: 3 }, 1000)
          .easing(TWEEN.Easing.Linear.None)
          .start();
        new TWEEN.Tween(item.scale)
          .to({ z: scale }, 800)
          .easing(TWEEN.Easing.Back.InOut)
          .start();
      }
      item.up = true;
    }
  })
}
const onMouseMove = (event) => {
  mouse.x = (event.clientX / canvasContainer.value.offsetWidth) * 2 - 1
  mouse.y = -(event.clientY / canvasContainer.value.offsetHeight) * 2 + 1
  tooltip.value.style.left = event.clientX + 2 + 'px'
  tooltip.value.style.top = event.clientY + 2 + 'px'
}
// 设置光线投射器和鼠标位置，用于检测鼠标悬停对象
function setRaycaster() {
  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();
  window.addEventListener('mousemove', onMouseMove, false)
}
function getMaxGreaterThanTarget(arr, target, key) {
  // 使用 filter 获取比目标数字大的对象
  const greaterObjects = arr.filter(obj => obj[key] <= target);

  // 如果没有比目标数字大的对象,返回 undefined
  if (greaterObjects.length === 0) {
    return undefined;
  }

  // 使用 Math.max() 获取最大值
  const maxGreaterNumber = Math.max(...greaterObjects.map(obj => obj[key]));

  // 找到具有最大值的对象
  const maxGreaterObject = greaterObjects.find(obj => obj[key] === maxGreaterNumber);

  return maxGreaterObject;
}
//窗口大小改变时，更新摄像机的宽高比和渲染器的大小
function handleResize() {
  if (camera && renderer && canvasContainer.value) {
    camera.aspect = canvasContainer.value.offsetWidth / canvasContainer.value.offsetHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(canvasContainer.value.offsetWidth, canvasContainer.value.offsetHeight);
    CSS2dRender.setSize(canvasContainer.value.offsetWidth, canvasContainer.value.offsetHeight);
    CSS3DRender.setSize(canvasContainer.value.offsetWidth, canvasContainer.value.offsetHeight);
  }
}
const onClick = (event) => {
  if (intersectObject) {
    const mesh = intersectObject.object;
    switch (state) {
      case 'china': {
        if (mesh.parent.properties.name !== '湖北省')
          return;
        provinceScene = new THREE.Scene();
        provinceScene.add(directionalLight1.clone());
        provinceScene.add(directionalLight2).clone();
        provinceScene.add(background.clone());
        provinceLabelList.forEach(item => {
          item.hide();
        })
        const fileLoad = new THREE.FileLoader();
        fileLoad.load('/json/' + mesh.parent.properties.name + '.json', (cityData) => {
          fileLoad.load('/json/' + mesh.parent.properties.name + '长江.json', (riverData) => {
            const riverJsonData = JSON.parse(riverData);
            const cityJsondata = JSON.parse(cityData);
            generateCity(cityJsondata);
            provinceYangtzeRiverLine = generateMainstreamYangtzeRiver(riverJsonData, depth / 4);
            provinceScene.add(provinceYangtzeRiverLine);
            const [x, y] = projection(mesh.parent.properties.center);
            lastCameraPosition = { ...camera.position };
            // new TWEEN.Tween(control.target)
            //   .to({ x: x, y: -y, z: 0 }, 2000)
            //   .easing(TWEEN.Easing.Quadratic.InOut)
            //   .start();
            camera.lookAt({ x: x, y: -y, z: 0 });
            control.target.set(x, -y, 0);
            new TWEEN.Tween(camera.position)
              .to({ x: x, y: -y - 8, z: 12 }, 1000)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .start();
            // provinceYangtzeRiverLine.geometry.instanceCount = 100;
            new TWEEN.Tween(provinceYangtzeRiverLine.geometry)
              .to({ instanceCount: 235 }, 3000)
              .onComplete(() => {
                let flyLine = createFlyCurve(provinceYangtzeRiverPoints, false, new THREE.Color('#e4f7ff'));
                provinceScene.add(flyLine);
              })
              .start();
            // new TWEEN.Tween(provinceYangtzeRiverLine.geometry)
            //   .to({ instanceCount: provinceYangtzeRiverLine.geometry._maxInstanceCount }, 6000)
            //   .start();
          })

        })
        state = 'province';
        break;
      }
      case 'province': {
        if (mesh.parent.properties.name !== '武汉市')
          return;

        cityScene = new THREE.Scene();
        cityScene.add(directionalLight1.clone());
        cityScene.add(directionalLight2).clone();
        cityScene.add(background.clone());
        cityLabelList.forEach(item => {
          item.hide();
        })
        const fileLoad = new THREE.FileLoader();
        fileLoad.load('/json/' + mesh.parent.properties.name + '.json', (districtData) => {
          fileLoad.load('/json/' + mesh.parent.properties.name + '长江.json', (riverData) => {
            fileLoad.load('/json/' + mesh.parent.properties.name + '长江支流.json', (riverData2) => {
              const riverJsonData = JSON.parse(riverData);
              const riverJsonData2 = JSON.parse(riverData2);
              const districtJsondata = JSON.parse(districtData);
              generateDistrict(districtJsondata);
              cityYangtzeRiverLine = generateMainstreamYangtzeRiver(riverJsonData, depth / 12);
              const tributarYangtzeRiverLine = generateTributaryYangtzeRiver(riverJsonData2, depth / 12);
              
              cityScene.add(cityYangtzeRiverLine);
              const [x, y] = projection(mesh.parent.properties.center);
              lastCameraPosition = { ...camera.position };
              // new TWEEN.Tween(control.target)
              //   .to({ x: x, y: -y, z: 0 }, 2000)
              //   .easing(TWEEN.Easing.Quadratic.InOut)
              //   .start();
              camera.lookAt({ x: x, y: -y, z: 0 });
              control.target.set(x, -y, 0);
              new TWEEN.Tween(camera.position)
                .to({ x: x, y: -y - 2, z: 3 }, 1000)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .start();
              new TWEEN.Tween(cityYangtzeRiverLine.geometry)
                .to({ instanceCount: 52 }, 3000)
                .onComplete(() => {
                  let flyLine = createFlyCurve(cityYangtzeRiverPoints, false, new THREE.Color('#e4f7ff'));
                  cityScene.add(flyLine);
                  cityScene.add(tributarYangtzeRiverLine);
                })
                .start();
            })

          })
        })
        state = 'city';
        break;
      }
      case 'city': {
        let [x, y] = projection(mesh.parent.properties.center);
        createTag([x, -y], mesh.parent);
        break;
      }
    }
  }
}
const onRtClick = (event) => {
  event.preventDefault();
  switch (state) {
    case 'province': {
      state = 'china';
      provinceLabelList.forEach(item => {
        item.show();
      })
      cityLabelList.forEach(item => {
        item.hide();
      })
      camera.lookAt({ x: 0, y: 0, z: 0 });
      control.target.set(0, 0, 0);
      new TWEEN.Tween(camera.position)
        .to({ x: 0, y: -58, z: 70 }, 2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
      // 相机z轴动画
      break;
    }
    case 'city': {
      state = 'province';
      cityLabelList.forEach(item => {
        item.show();
      })
      provinceLabelList.forEach(item => {
        item.hide();
      })
      districtLabelList.forEach(item => {
        item.hide();
      })
      const { x, y } = lastCameraPosition;
      camera.lookAt({ x: x, y: y + 8, z: 0 });
      control.target.set(x, y + 8, 0);
      // new TWEEN.Tween(control.target)
      //   .to({ x: x, y: y + 8, z: 0 }, 2000)
      //   .easing(TWEEN.Easing.Quadratic.InOut)
      //   .start();
      new TWEEN.Tween(camera.position)
        .to(lastCameraPosition, 2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
      // 相机z轴动画
      break;
    }
  }
}
const onDbClick = (event) => {
  if (intersectObject) {
    const [x, y] = projection(intersectObject.object.parent.properties.center);
    new TWEEN.Tween(camera.position)
      .to({ x: x, y: -y, z: 80 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(function (obj) {
        camera.lookAt({ x: x, y: -y, z: 0 });
        control.target.set(x, -y, 0);
        control.update();
      })
      .start();
  }
}
const initAnimate = () => {
  setTimeout(() => {
    const cloud = chinaScene.children.find(item => item.name === 'cloud');
    cloud.children.forEach((item) => {
      new TWEEN.Tween(item.material)
        .to({ opacity: 0 }, 3000)
        .onComplete(() => {
          chinaScene.remove(cloud);
        })
        .start();
    })
    new TWEEN.Tween(chinaLine.geometry)
      .to({ instanceCount: chinaLine.geometry._maxInstanceCount }, 3000)
      .delay(1000)
      .start();
    // 相机z轴动画
    new TWEEN.Tween(camera.position)
      .to({ z: 70 }, 5000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        // 相机y轴动画
        new TWEEN.Tween(camera.position)
          .to({ y: -58 }, 3000)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .start();
        // ui的动画
        dataView.value.animate();

        new TWEEN.Tween(chinaYangtzeRiverLine.geometry)
          .to({ instanceCount: chinaYangtzeRiverLine.geometry._maxInstanceCount }, 6000)
          .onUpdate(() => {
            const max = getMaxGreaterThanTarget(growAnimateList, chinaYangtzeRiverLine.geometry.instanceCount, 'value');
            if (max) {
              growAnimate(max.name);
            }
          })
          .onComplete(() => {
            const hubei = chinaScene.children.find(item => item.name === 'provinceGroup').children.find(item => item.properties.name === beginHighlight);
            let [x, y] = projection(hubei.properties.center);
            createLight([x, -y], hubei);
            // hubei.focus = true;
            // upTweenAnimations[hubei.id] = new TWEEN.Tween(hubei.position)
            //   .to({ z: 3 }, 800)
            //   .easing(TWEEN.Easing.Sinusoidal.Out)
            //   .start();
            // hubei.children.filter(item => item.name === 'province').forEach(province => {
            //   province.material[0].color.set(hoverPlateColor);
            // })
            let flyLine = createFlyCurve(chinaYangtzeRiverPoints, false, new THREE.Color('#e4f7ff'));
            chinaScene.add(flyLine);
            beginAnimated = true;
          })
          .start();
      })
      .start();
  }, 500)

}
// 动画循环,用于渲染场景和更新状态
function animate() {
  requestAnimationFrame(animate);
  TWEEN.update();
  control.update();
  raycaster.setFromCamera(mouse, camera);
  const frameT = clock.getDelta();
  riverNormalMap.offset.x += 0.2;
  // 更新播放器相关的时间
  mixers.forEach(mixer => {
    mixer.update(frameT);
  })
  if (rotatingApertureMesh) {
    rotatingApertureMesh.rotation.z += 0.005;
    if (rotatingApertureMesh.rotation.z >= 200) {
      rotatingApertureMesh.rotation.z = 0;
    }
  }

  // showTip();
  // composer.render();
  // renderer.render(chinaScene, camera);
  switch (state) {
    case 'china': {
      if (CSS2dRender) {
        CSS2dRender.render(chinaScene, camera);
      }
      if (CSS3DRender) {
        CSS3DRender.render(chinaScene, camera);
      }
      renderer.render(chinaScene, camera);
      hoverAnimate(provinceGroup, chinaScene, runoffProvinceJsondata, 'province', upZ);
      break;

    }
    case 'province': {
      renderer.render(provinceScene, camera);
      if (CSS2dRender) {
        CSS2dRender.render(provinceScene, camera);
      }
      if (CSS3DRender) {
        CSS3DRender.render(provinceScene, camera);
      }
      hoverAnimate(cityGroup, provinceScene, [], 'city', upZ / 4);
      break;
    }
    case 'city': {
      if (CSS2dRender) {
        CSS2dRender.render(cityScene, camera);
      }
      if (CSS3DRender) {
        CSS3DRender.render(cityScene, camera);
      }
      renderer.render(cityScene, camera);
      hoverAnimate(districtGroup, cityScene, [], 'district', upZ / 18);
      break;
    }
  }

}
// 组件挂载时的初始化逻辑
onMounted(async () => {
  autofit.init({
    dh: 1080,
    dw: 1920,
    el: "body",
    ignore: [
      {
        el: "#container",
      },
    ],
    resize: true
  })
  await loadTexture();
  chinaScene = new THREE.Scene();
  await loadJson();
  setRaycaster();
  initLight();
  initCamera();
  CSS2dRender = initCSS2DRender(canvasContainer.value);
  CSS3DRender = initCSS3DRender(canvasContainer.value);
  initCloud();
  initBackground();
  initRenderer();
  canvasContainer.value.appendChild(renderer.domElement);
  rotatingApertureMesh = initRotatingAperture(chinaScene, 150);
  control = new OrbitControls(camera, canvasContainer.value)
  control.minPolarAngle = 0.72;
  control.maxPolarAngle = Math.PI * 0.74;
  control.minAzimuthAngle = -Math.PI * (25 / 180);
  control.maxAzimuthAngle = Math.PI * (25 / 180);
  control.enableDamping = true;
  control.enablePan = false;  // 禁止右键拖拽
  control.enableZoom = true; // 禁止缩放
  control.enableRotate = true; // 禁止旋转
  control.dampingFactor = 0.08;

  animate();
  window.addEventListener('resize', handleResize)
  // window.addEventListener('dblclick', onDbClick)
  window.addEventListener('click', onClick)
  window.addEventListener('contextmenu', onRtClick)
  initAnimate();
})

onUnmounted(() => {
  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('resize', handleResize)
  renderer.dispose();
  // 流光效果js文件中的定时器，为了避免内存泄漏问题，最好在销毁前清除
  clearInterval(timerFlyCurve);
})
</script>

<style lang="scss">
#container {
  /* border: 1px solid black; */
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 100;
  background-color: #0d1418;
}

#tooltip {
  position: absolute;
  z-index: 2;
  background: white;
  padding: 10px;
  border-radius: 5px;
  visibility: hidden;
}

.map-tag {
  width: 160px;
  height: 90px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('~@/assets/gif/卷轴.gif');
  top: -15px;
}

.province-label {
  font-weight: 500;
  font-size: 19px;
  color: #FFFFFF;
  line-height: 14px;
  text-shadow: 1px 1px 1px #000000;
  opacity: 0;
  transition: .5s;
  position: relative;

  &::after {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(250, 59, 59);
  }
}

.city-label {
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 14px;
  text-shadow: 1px 1px 1px #000000;
  position: relative;

  &::after {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(250, 59, 59);
  }
}

.district-label {
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 14px;
  text-shadow: 1px 1px 1px #000000;
  position: relative;

  &::after {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(250, 59, 59);
  }
}
</style>