<template>
    <img src="/images/bg/border.png" class="animate__animated" ref="bg" id="bg">
    <div class="top-bg-warp">
        <div style="background-image: url('/images/bg/topBg.png');" class="top-bg  animate__animated">
            <div class="streamer">
                <svg class="" xmlns="http://www.w3.org/2000/svg" width="645.203" height="57.703"
                    viewBox="0 0 645.203 57.703">
                    <!-- <path id="路径_3081" data-name="路径 3081" d="M51.741,6393.01l-1.84,15.025,16.557,25.142h91.065l11.038,15.944H343.333l7.359-8.892h42.742l7.267,8.892h174.41l11.561-16.475h90.8l16.6-25.063-1.817-15.608" transform="translate(-49.383 -6391.917)" fill="none" stroke="#707070" stroke-width="1"/> -->
                    <path class="streamer-line" id="路径_3081" data-name="路径 3081"
                        d="M51.741,6393.01l-1.84,15.025,16.557,25.142h91.065l11.038,15.944H343.333l7.359-8.892h42.742l7.267,8.892h174.41l11.561-16.475h90.8l16.6-25.063-1.817-15.608"
                        transform="translate(-49.383 -6391.917)" fill="none" stroke="#707070" stroke-width="1" />
                </svg>
            </div>
        </div>
    </div>



    <div id="top">
        <div class="animate__animated" id="weather">
            <span style="margin-left: 65px;">
                小雨转多云 <span style="color: #8FC5F3;">28°C/30°C</span>
            </span>

        </div>
        <div class="animate__animated" ref="topTitle" id="top-title">
            <img width="330" height="32" src="/images/bg/topTitle.png">
        </div>

        <div class="animate__animated" id="time">
            <span>{{ ymd }}</span>
            <span style="color: #DEF4FF; margin-right: 58px; margin-left: 20px;">{{ hms }}</span>
        </div>
    </div>
    <div class="top-tip">
        <div style="animation-delay: 0.4s;" class="animate__animated top-tip-item">
            <div class="top-tip-item-title">已接入本站城市数</div>
            <div style="color: #3C90FF;text-shadow: 0px 3px 6px #8FC5F3;" class="top-tip-item-value">2</div>
        </div>
        <div style="animation-delay: 0.2s;" class="animate__animated top-tip-item">
            <div class="top-tip-item-title">待接入本站城市数</div>
            <div style="color: #F5D85D;text-shadow: 0px 3px 6px #FFE490;" class="top-tip-item-value">9</div>
        </div>
        <div style="animation-delay: 0.4s;" class="animate__animated top-tip-item">
            <div class="top-tip-item-title">未接入本站城市数</div>
            <div style="color: #BAD1E1;text-shadow: 0px 3px 6px #9EBCD1;" class="top-tip-item-value">2</div>
        </div>
    </div>
    <div id="left">
        <div @animationend="initUserTotalChart()" class="animate__animated left" id="user-total">
            <div class="title">用户数量</div>
            <div id="uesr-total-content">
                <div id="uesr-total-chart">
                    <img id="user-total-bg" src="/images/bg/user-total-bg.png">
                    <div class="user-total-chart" ref="userTotalChart">
                    </div>
                </div>
                <div id="user-total-tip">
                    <div v-for="(item, index) in userTotalChartData" :key="index" class="user-total-tip-item">
                        <div :style="{ color: item.color }" class="user-total-tip-item-name">
                            <div :style="{ backgroundColor: item.color }" class="user-total-tip-item-icon"></div>
                            {{ item.name }}
                        </div>
                        <div class="user-total-tip-item-value">
                            {{ item.value }} 人
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div @animationend="initSiteVisitChart()" class="animate__animated left" id="site-visit">
            <div class="title">全站访问量</div>
            <div id="site-visit-content">
                <div ref="siteVisitChart" id="site-visit-chart">

                </div>
                <img src="/images/bg/site-visit-bg.png" id="site-visit-bg">
            </div>
        </div>
        <div @animationstart="initChildSiteVistChart()" class="animate__animated left" id="child-site-visit">
            <div class="title">子站访问量</div>
            <div id="child-site-visit-content">
                <div ref="childSiteVisitChart" id="child-site-visit-chart">

                </div>
            </div>
        </div>
    </div>
    <div id="right">
        <div class="animate__animated right" id="articles-number">
            <div class="title">文章数量</div>
            <div id="articles-number-rotate-content">
                <div class="articles-number-rotate">
                    <div style="background-image: url('/images/bg/文章数量_1.png');"
                        class="articles-number-rotate-item articles-number-rotate-item1">
                    </div>
                    <div style="background-image: url('/images/bg/文章数量_2.png');"
                        class="articles-number-rotate-item articles-number-rotate-item2">
                    </div>
                    <div style="background-image: url('/images/bg/文章数量_3.png');"
                        class="articles-number-rotate-item articles-number-rotate-item3">
                    </div>
                    <div style="background-image: url('/images/bg/文章数量_2.png');"
                        class="articles-number-rotate-item articles-number-rotate-item4">
                    </div>
                </div>
                <div ref="articlesNumberRotateText" class="articles-number-rotate-text">
                    <div class="articles-number-rotate-text-item articles-number-rotate-text-item1">
                        <div class="articles-number-rotate-text-number">{{ articlesNumberRotateData[0].value }}篇</div>
                        <div class="articles-number-rotate-text-name">{{ articlesNumberRotateData[0].name }}</div>
                    </div>
                    <div class="articles-number-rotate-text-item articles-number-rotate-text-item2">
                        <div class="articles-number-rotate-text-number">{{ articlesNumberRotateData[1].value }}篇</div>
                        <div class="articles-number-rotate-text-name">{{ articlesNumberRotateData[1].name }}</div>
                    </div>
                    <div class="articles-number-rotate-text-item articles-number-rotate-text-item3">
                        <div class="articles-number-rotate-text-number">{{ articlesNumberRotateData[2].value }}篇</div>
                        <div class="articles-number-rotate-text-name">{{ articlesNumberRotateData[2].name }}</div>
                    </div>
                </div>
            </div>
            <div id="articles-number-select">
                <!-- <button @click="changeSelect('prev')"></button>
                <button @click="changeSelect('next')"></button> -->
                <div @click="changeSelect(item, index)" v-for="(item, index) in articlesNumberData" :key="index"
                    :class="item.acitve ? 'select-active' : ''" class="articles-number-select-item">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="animate__animated right" id="publications-rank">
            <div class="title">发布量排行</div>
            <div id="publications-rank-content">
                <table id="publications-rank-table">
                    <thead>
                        <tr class="publications-rank-table-row">
                            <th class="publications-rank-table-th">排名</th>
                            <th class="publications-rank-table-th">站名</th>
                            <th class="publications-rank-table-th">发布数量</th>
                            <th class="publications-rank-table-th">最后更新时间</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="publications-rank-table-row" v-for="(item, index) in publicationsRankData"
                            :key="index">
                            <td :style="{ backgroundImage: `url(${getRankBg(index)})` }"
                                class="publications-rank-table-rank">{{ index + 1 }}</td>
                            <td :class="index % 2 === 0 ? 'publications-rank-table-bg' : ''" style="color: #ffffff;">{{
                item.name }}</td>
                            <td style="color: #82C3FF;">{{ item.releasesNum }}篇</td>
                            <td style="color: #BFEDFF;">{{ item.lastUpdate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div id="bottom">
        <div style="background-image: url('/images/bg/sift-region-bg.png');" class="sift-region animate__animated">
        </div>
        <div style="background-image: url('/images/bg/search-region-bg.png');" class="search-region animate__animated">
        </div>
    </div>
</template>

<script lang='ts'>
import { useNow, useDateFormat } from '@vueuse/core'
import * as echarts from "echarts";
import 'animate.css';
export default {
    name: 'DataView',
}
</script>

<script lang='ts' setup>
import { ref, onMounted, computed } from 'vue'

type publicationsRank = {
    name: string,
    releasesNum: number,
    lastUpdate: string,
}

type expose = {
    /**
   * 动画
   */
    animate: () => void;
}

const now = useNow();
const ymd = useDateFormat(now, 'YYYY-MM-DD');
const hms = useDateFormat(now, 'HH:mm:ss');
const bg = ref();
const topTitle = ref();
const userTotalChart = ref();
const userTotalChartData = [
    { value: 845, name: '全站人数', color: '#66A9E0' },
    { value: 481, name: '当前在线人数', color: '#33CB9D' },
    { value: 127, name: '当前访问人数', color: '#FFE260' },
];
const siteVisitChart = ref();
const siteVisitChartDataKey = [
    { name: '综合资讯' },
    { name: '社会与文化' },
    { name: '法律与文化' },
    { name: '环境与文化' },
    { name: '经济与文化' },
    { name: '人口与文化' },
    { name: '政策与文化' },
    { name: '技术与文化' },
];
const siteVisitChartData = [4200, 3000, 20000, 35000, 50000, 18000, 4000, 2800];
const childSiteVisitChart = ref();

const articlesNumberRotateText = ref();
const articlesNumberData = ref([
    {
        name: '本站总数',
        value: 124,
        acitve: true,
    },
    {
        name: '人口与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '法律与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '环境与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '政策与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '经济与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '技术与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '社会与文化',
        value: 124,
        acitve: false,
    },
    {
        name: '综合与文化',
        value: 124,
        acitve: false,
    },
]);
const selectRotate = ref(0);
const articlesNumberRotateData = computed(() => {
    const prev = articlesNumberData.value[selectRotate.value - 1] || articlesNumberData.value[articlesNumberData.value.length - 1];
    const now = articlesNumberData.value[selectRotate.value];
    const next = articlesNumberData.value[selectRotate.value + 1] || articlesNumberData.value[0];
    return [prev, now, next];
})
const publicationsRankData = ref<publicationsRank[]>([
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
    {
        name: '湖北省武汉市',
        releasesNum: 128,
        lastUpdate: '2023-01-01',
    },
]);

const initUserTotalChart = () => {
    const chart = echarts.init(userTotalChart.value, "macarons", {
        width: "180px",
        height: "180px",
        devicePixelRatio: 3,
    });
    const option = {
        textStyle: {
            fontFamily: 'Noto Sans SC',
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['55%', '70%'],
                avoidLabelOverlap: false,
                roseType: 'radius',
                padAngle: 3,
                itemStyle: {
                    borderRadius: 3,
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 18,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: userTotalChartData,
            }
        ]
    };
    chart.setOption(option);
}
const initSiteVisitChart = () => {
    const chart = echarts.init(siteVisitChart.value, "macarons", {
        width: "320px",
        height: "220px",
        devicePixelRatio: 3,
    });
    const option = {
        textStyle: {
            fontFamily: 'Noto Sans SC',
        },
        radar: {
            // shape: 'circle',
            splitArea: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            radius: 68,
            axisName: {
                color: '#ffffff',
                fontSize: 14,
                fontWeight: 400,
            },
            indicator: siteVisitChartDataKey
        },
        series: [
            {
                name: 'Budget vs spending',
                type: 'radar',
                areaStyle: {
                    color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                        {
                            color: 'rgba(0, 61, 110, 0)',
                            offset: 0
                        },
                        {
                            color: 'rgba(30, 128, 207, 0.58)',
                            offset: 1
                        }
                    ])
                },
                itemStyle: {
                    opacity: 0,
                },
                data: [
                    {
                        value: siteVisitChartData,
                        name: 'Allocated Budget'
                    },
                ]
            }
        ]
    };
    chart.setOption(option);
}
const initChildSiteVistChart = () => {
    const chart = echarts.init(childSiteVisitChart.value, "macarons", {
        width: "435px",
        height: "320px",
        devicePixelRatio: 3,
    });
    const option = {
        textStyle: {
            fontFamily: 'Noto Sans SC',
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            show: true,
            orient: 'horizontal',
            top: 20,
            left: 50,
            textStyle: {
                color: '#ffffff',
                fontSize: 12,
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                axisLabel: {
                    interval: 0,
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#8FABBF'
                },
                data: ["青海",
                    "四川",
                    "西藏",
                    "云南",
                    "重庆",
                    "湖北",
                    "湖南",
                    "江西",
                    "安徽",
                    "江苏",
                    "上海"]
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    interval: 0,
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#8FABBF'
                },
            }
        ],

        dataZoom: [
            {
                type: 'inside',
                startValue: 0,
                endValue: 7
            }
        ],
        series: [
            {
                name: '今日访问量',
                type: 'bar',
                showBackground: true,
                animationDelay: 500,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            color: 'rgba(122, 163, 204, 0.10)',
                            offset: 1
                        },
                        {
                            color: 'rgba(64, 162, 255, 1)',
                            offset: 0
                        }
                    ])
                },

                data: [320, 332, 301, 334, 390, 330, 320, 332, 301, 334, 390]
            },
            {
                name: '今日在线量',
                type: 'bar',
                showBackground: true,
                animationDelay: 500,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            color: 'rgba(255, 229, 144, 0.10)',
                            offset: 1
                        },
                        {
                            color: 'rgba(245, 216, 93, 1)',
                            offset: 0
                        }
                    ])
                },
                data: [360, 212, 31, 94, 120, 310, 890, 312, 211, 314, 290]
            },
        ]
    }
    chart.setOption(option);
}

const changeSelect = (item: any, index: number) => {
    articlesNumberData.value.forEach(item => { item.acitve = false })
    item.acitve = true;
    let flag = '';
    if (selectRotate.value < index) {
        flag = 'next';
    } else if (selectRotate.value > index) {
        flag = 'prev';
    }
    setTimeout(() => {
        selectRotate.value = index;
    }, 800)

    articlesNumberRotateText.value.animate([
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
    ], {
        duration: 1200,
    })
    const articlesNumberRotateItem1 = document.querySelector('.articles-number-rotate-item1') as HTMLElement;
    const articlesNumberRotateItem2 = document.querySelector('.articles-number-rotate-item2') as HTMLElement;
    const articlesNumberRotateItem3 = document.querySelector('.articles-number-rotate-item3') as HTMLElement;
    const articlesNumberRotateItem4 = document.querySelector('.articles-number-rotate-item4') as HTMLElement;
    if (flag === 'prev') {
        articlesNumberRotateItem1.className = articlesNumberRotateItem1.className.replace('articles-number-rotate-item1', 'articles-number-rotate-item2');
        articlesNumberRotateItem2.className = articlesNumberRotateItem2.className.replace('articles-number-rotate-item2', 'articles-number-rotate-item3');
        articlesNumberRotateItem3.className = articlesNumberRotateItem3.className.replace('articles-number-rotate-item3', 'articles-number-rotate-item4');
        articlesNumberRotateItem4.className = articlesNumberRotateItem4.className.replace('articles-number-rotate-item4', 'articles-number-rotate-item1');
    } else if (flag === 'next') {
        articlesNumberRotateItem1.className = articlesNumberRotateItem1.className.replace('articles-number-rotate-item1', 'articles-number-rotate-item4');
        articlesNumberRotateItem2.className = articlesNumberRotateItem2.className.replace('articles-number-rotate-item2', 'articles-number-rotate-item1');
        articlesNumberRotateItem3.className = articlesNumberRotateItem3.className.replace('articles-number-rotate-item3', 'articles-number-rotate-item2');
        articlesNumberRotateItem4.className = articlesNumberRotateItem4.className.replace('articles-number-rotate-item4', 'articles-number-rotate-item3');
    }
}
const getRankBg = (index: number) => {
    switch (index) {
        case 0:
            return '/images/bg/first.png';
        case 1:
            return '/images/bg/second.png';
        case 2:
            return '/images/bg/three.png';
        default:
            return '/images/bg/other.png';
    }
}
const animate = () => {
    const animateDiv = document.querySelectorAll('.animate__animated');
    animateDiv.forEach((item) => {
        if (item.className.includes('left')) {
            item.classList.add('animate__fadeInLeft');
        } else if (item.className.includes('right')) {
            item.classList.add('animate__fadeInRight');
        } else {
            item.classList.add('animate__fadeInDown');
        }
    })
}
/**
 * 暴露的方法
 */
// eslint-disable-next-line no-undef
defineExpose<expose>({
    animate,
});
</script>

<style lang='scss' scoped>
/* Add custom styles here */
.animate__animated {
    opacity: 0;
}


@keyframes move {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: -640;
    }
}

#bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 101;
    left: 0;
    top: 0;
    pointer-events: none;
}

.top-bg-warp {
    position: absolute;
    z-index: 101;
    width: 1493px;
    height: 86px;
    left: 50%;
    top: 1px;
    transform: translateX(-50%);
    .top-bg {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .streamer {
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 102;

            .streamer-line {
                stroke: #fc0;
                stroke-dasharray: 60, 640;
                stroke-dashoffset: 0;
                animation: move 2.4s infinite linear;
                filter: drop-shadow(0 4px 8px #fc0);
            }
        }
    }
}


.title {
    font-weight: 400;
    font-size: 20px;
    color: #C3DBF2;
    text-align: left;
    padding-left: 45px;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 8px;
        width: 100%;
        height: 25px;
        background-image: url('~@/assets/bg/titleBg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

#top {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
    z-index: 102;

    #weather {
        font-weight: 400;
        font-size: 15px;
        color: #DEF4FF;
        flex: 1;
        text-align: left;
    }

    #top-title {
        pointer-events: none;
        width: 330px;
        height: 32px;
        margin-top: 10px;
        margin-bottom: 30px;
        flex: 1;
    }

    #time {
        font-weight: 400;
        font-size: 15px;
        color: #8FC5F3;
        text-align: right;
        flex: 1;
    }
}

.top-tip {
    position: absolute;
    width: 600px;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
    z-index: 102;
    display: flex;
    justify-content: space-between;

    .top-tip-item {
        width: 140px;
        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background-image: url('~@/assets/bg/top-tip.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 2px 2px 2px 2px;

        .top-tip-item-title {
            font-weight: 400;
            font-size: 15px;
            color: #DEF4FF;
            text-shadow: 1px 1px 1px #000000;
            margin-top: 10px;
        }

        .top-tip-item-value {
            margin-bottom: 5px;
            font-weight: 800;
            font-size: 36px;
        }
    }
}

#left {
    position: absolute;
    width: 435px;
    height: 100%;
    left: 62px;
    top: 110px;
    z-index: 102;

    #user-total {
        height: 275px;
        display: flex;
        flex-direction: column;
        animation-delay: 0.3s;

        #uesr-total-content {
            display: flex;
            height: 100%;

            #uesr-total-chart {
                display: flex;
                align-items: center;
                justify-items: center;
                position: relative;
                width: 180px;

                #user-total-bg {
                    position: absolute;
                    width: 160px;
                    height: 160px;
                    left: calc(50% - 2px);
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            #user-total-tip {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 30px;

                .user-total-tip-item {
                    width: 210px;
                    height: 28px;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px;
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 2px 2px 2px 2px;

                    .user-total-tip-item-name {
                        font-weight: 400;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .user-total-tip-item-icon {
                            width: 9px;
                            height: 9px;
                        }
                    }

                    .user-total-tip-item-value {
                        font-weight: 400;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }
        }

    }

    #site-visit {
        height: 320px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        animation-delay: 0.6s;

        #site-visit-content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            #site-visit-bg {
                position: absolute;
                width: 180px;
                height: 256px;
                top: 70px;
                transform: translateX(4px);
            }
        }
    }

    #child-site-visit {
        height: 300px;
        display: flex;
        flex-direction: column;
        animation-delay: 0.9s;

        #child-site-visit-content {
            height: 100%;

        }
    }
}

#right {
    position: absolute;
    width: 435px;
    height: 100%;
    right: 62px;
    top: 110px;
    z-index: 102;

    #articles-number {
        height: 500px;
        display: flex;
        flex-direction: column;
        animation-delay: 0.4s;

        #articles-number-rotate-content {
            height: 50%;
            position: relative;

            .articles-number-rotate {
                position: relative;
                height: 100%;

                .articles-number-rotate-item {
                    position: absolute;
                    width: 85px;
                    height: 82px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    transition: 1s;
                    transition-timing-function: cubic-bezier(0.36, 0, 0.64, 1);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-weight: 400;
                    font-size: 12px;
                    color: #8FABBF;
                }

                .articles-number-rotate-item1 {
                    left: 70px;
                    top: 40px;
                    transform: scale(0.8);
                    opacity: 1;
                }

                .articles-number-rotate-item2 {
                    left: 175px;
                    top: 80px;
                    transform: scale(1);
                    opacity: 1;
                }

                .articles-number-rotate-item3 {
                    left: 285px;
                    top: 40px;
                    transform: scale(0.8);
                    opacity: 1;
                }

                .articles-number-rotate-item4 {
                    left: 173px;
                    top: 20px;
                    transform: scale(0.5);
                    opacity: 0;
                }
            }

            .articles-number-rotate-text {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                .articles-number-rotate-text-item {
                    position: absolute;
                    width: 85px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-weight: 400;

                    color: #8FABBF;
                    .articles-number-rotate-text-name {
                        transform: translateY(15px);
                    }
                }

                .articles-number-rotate-text-item1 {
                    left: 70px;
                    top: 40px;
                    transform: scale(0.8);
                    opacity: 1;
                    font-size: 14px;
                }

                .articles-number-rotate-text-item2 {
                    left: 175px;
                    top: 80px;
                    transform: scale(1);
                    opacity: 1;
                    font-size: 16px;
                    color: #FFFFFF;
                }

                .articles-number-rotate-text-item3 {
                    left: 285px;
                    top: 40px;
                    transform: scale(0.8);
                    opacity: 1;
                    font-size: 14px;
                }
            }
        }

        #articles-number-select {
            height: 50%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;

            .articles-number-select-item {
                width: 120px;
                height: 32px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 14px;
                color: #8FABBF;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-color: rgba(104, 150, 173, 0.16);
                box-shadow: 3px 3px 18px 1px #000000;
                border-radius: 2px;
            }

            .select-active {
                background-color: none !important;
                background-image: url('~@/assets/bg/文章数量-名称.png');
            }
        }
    }

    #publications-rank {
        height: 420px;
        position: relative;
        display: flex;
        flex-direction: column;
        animation-delay: 0.8s;

        &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            width: 100%;
            height: 24px;
            background: linear-gradient(180deg, rgba(16, 29, 51, 0) 0%, #0F1D34 82%, #0F1D33 100%);
        }

        #publications-rank-content {
            height: 100%;
            padding: 0 10px;
            margin-top: 10px;
            position: relative;
            overflow-y: scroll;
            scrollbar-width: none;
            /* firefox */
            -ms-overflow-style: none;

            /* IE 10+ */
            &::-webkit-scrollbar {
                display: none;
                /* Chrome Safari */
            }

            #publications-rank-table {
                width: 100%;
                font-weight: 400;
                font-size: 15px;
                border-collapse: separate;
                border-spacing: 0px 10px;

                .publications-rank-table-row {
                    height: 32px;
                    margin-bottom: 10px;

                    .publications-rank-table-th {
                        color: #8FABBF;
                    }

                    .publications-rank-table-rank {
                        width: 46px;
                        height: 24px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        background-position: center;
                        background-size: 60% 100%;
                        background-repeat: no-repeat;
                    }

                    .publications-rank-table-bg {
                        position: relative;

                        &::after {

                            position: absolute;
                            left: 0;
                            top: 0;
                            content: '';
                            width: 270%;
                            height: 100%;
                            border-radius: 2px;
                            background: linear-gradient(180deg, rgba(82, 165, 206, 0.17) 0%, rgba(52, 75, 87, 0.1) 100%);
                            border: 1px solid rgba(24, 155, 255, 0.3);
                            background-image: url('~@/assets/bg/发布量排行背景.png');
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
    }
}
#bottom {
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    display: flex;
    gap: 20px;
    .sift-region {
        width: 136px;
        height: 38px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .search-region {
        width: 420px;
        height: 38px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
</style>