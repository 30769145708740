import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import { random } from '@/utils'
// 纹理加载器
const textureLoader = new THREE.TextureLoader()
// 默认参数
let defaultOptions = {
  pointTextureUrl: '/images/texture/标注.png',
  lightHaloTextureUrl: '/images/texture/标注光圈.png',
  lightPillarUrl: '/images/texture/光柱.png',
  scaleFactor: 1, // 缩放系数
}
/**
 * 创建标记点
 * @param {*} R 地球半径，根据R来进行缩放
 * @returns
 */
const createPointMesh = (color) => {
  // 标记点：几何体，材质，
  const geometry = new THREE.PlaneGeometry(15, 15)
  const material = new THREE.MeshBasicMaterial({
    map: textureLoader.load(defaultOptions.pointTextureUrl),
    color: color,
    side: THREE.DoubleSide,
    transparent: true,
    depthWrite: false, //禁止写入深度缓冲区数据
  })
  let mesh = new THREE.Mesh(geometry, material)
  mesh.name = 'createPointMesh'
  // 缩放
  const scale = 0.15 * defaultOptions.scaleFactor
  mesh.scale.set(scale, scale, scale)
  return mesh
}
/**
 * 创建光圈
 * @param {*} R 地球半径，根据R来进行缩放
 * @returns
 */
const createLightHalo = (color) => {
  // 标记点：几何体，材质，
  const geometry = new THREE.PlaneGeometry(15, 15)
  const material = new THREE.MeshBasicMaterial({
    map: textureLoader.load(defaultOptions.lightHaloTextureUrl),
    color: color,
    side: THREE.DoubleSide,
    opacity: 0,
    transparent: true,
    depthWrite: false, //禁止写入深度缓冲区数据
  })
  let mesh = new THREE.Mesh(geometry, material)
  mesh.name = 'createLightHalo'
  // 缩放
  const scale = 0.3 * defaultOptions.scaleFactor
  mesh.scale.set(scale, scale, scale)
  // 动画延迟时间
  const delay = random(0, 2000)
  // 动画：透明度缩放动画
  mesh.tween1 = new TWEEN.Tween({ scale: scale, opacity: 0 })
    .to({ scale: scale * 1.5, opacity: 1 }, 1000)
    .delay(delay)
    .onUpdate(params => {
      let { scale, opacity } = params
      mesh.scale.set(scale, scale, scale)
      mesh.material.opacity = opacity
    })
  mesh.tween2 = new TWEEN.Tween({ scale: scale * 1.5, opacity: 1 })
    .to({ scale: scale * 2, opacity: 0 }, 1000)
    .onUpdate(params => {
      let { scale, opacity } = params
      mesh.scale.set(scale, scale, scale)
      mesh.material.opacity = opacity
    })
  mesh.tween1.chain(mesh.tween2)
  mesh.tween2.chain(mesh.tween1)
  mesh.tween1.start()
  return mesh
}
/**
 * 创建光柱
 * @param {*} lon
 * @param {*} lat
 * @param {*} heightScaleFactor 光柱高度的缩放系数
 * @returns
 */
export const createLightPillar = (lon, lat, heightScaleFactor = 1, color) => {
  let group = new THREE.Group()
  // 柱体高度
  const height = heightScaleFactor
  // 柱体的geo,6.19=柱体图片高度/宽度的倍数
  const geometry = new THREE.PlaneGeometry(height, height)
  // 柱体旋转90度，垂直于Y轴
  geometry.rotateX(Math.PI / 2)
  // 柱体的z轴移动高度一半对齐中心点
  geometry.translate(0, 0, height / 2)
  // 柱子材质
  const material = new THREE.MeshBasicMaterial({
    map: textureLoader.load(defaultOptions.lightPillarUrl),
    color: color,
    transparent: true,
    depthWrite: false,
    side: THREE.DoubleSide,
  })
  // 光柱01
  let light01 = new THREE.Mesh(geometry, material)
  light01.name = 'createLightPillar01'
  // 光柱02：复制光柱01
  let light02 = light01.clone()
  light02.name = 'createLightPillar02'
  // 光柱02，旋转90°，跟 光柱01交叉
  light02.rotateZ(Math.PI / 2)
  // 创建底部标点
  const bottomMesh = createPointMesh(color)
  // 创建光圈
  const lightHalo = createLightHalo(color)
  // 将光柱和标点添加到组里
  group.add(bottomMesh, lightHalo, light01, light02)
  // 设置组对象的姿态
  // group = setMeshQuaternion(group, R, lon, lat)
  group.position.set(lon, lat, 0)
  return group
}

