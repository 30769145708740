import { CSS3DObject, CSS3DRenderer } from 'three/addons/renderers/CSS3DRenderer.js'
/**
 * 初始化标签-
 * @param {*} container canvas内容容器
 * @returns
 */
export const initCSS3DRender = (container) => {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let css3dRender = new CSS3DRenderer() // 实例化css3d渲染器
  css3dRender.setSize(width, height) // 设置渲染器的尺寸
  css3dRender.domElement.style.position = 'absolute' // 设置定位位置
  css3dRender.domElement.style.left = '0px'
  css3dRender.domElement.style.top = '0px'
  css3dRender.domElement.style.pointerEvents = 'none' // 设置不能背选中
  container.appendChild(css3dRender.domElement) // 插入到容器当中
  return css3dRender
}
/**
 * 创建3d标签
 * @param {*} name  标签内容
 * @param {*} className 标签class
 * @returns
 */
export const create3DTag = (name = '', className = '', point) => {
  let tag = document.createElement('div')
  tag.innerHTML = name
  tag.className = className
  tag.style.pointerEvents = 'none'
  tag.style.visibility = 'visible'
  tag.style.position = 'absolute'
  
  // 如果className不存在，用以下样式
  if (!className) {
    tag.style.padding = '10px'
    tag.style.color = '#fff'
    tag.style.fontSize = '12px'
    tag.style.textAlign = 'center'
    tag.style.background = 'rgba(0,0,0,0.6)'
    tag.style.borderRadius = '4px'
  }
  let label = new CSS3DObject(tag)
  label.position.copy(point)
  /**
   * 标签显示，
   * @param {*} name 显示内容
   * @param {*} point 显示坐标
   */
  label.show = () => {
    label.element.style.visibility = 'visible'
  }
  /**
   * 隐藏
   */
  label.hide = () => {
    label.element.style.visibility = 'hidden'
  }
  return label
}

